<template>
    <div class="main">
        <div class="row">
            <div class="col-12">
                <Breadcrumb :breadcrumb="breadcrumbItems" />
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <div class="row">
                    <div class="col-12 mb-3">
                        <ProfileWithRate />
                    </div>
                    <div class="col-12">
                        <AvailableLesson />
                    </div>
                    <div class="col-12">
                        <review-lesson />
                    </div>
                </div>
            </div>
        </div>
        <MetaTags 
            title="プロフィール｜ResMom相談online"
            description="リセマム相談オンラインのプロフィールです。｜リセマム相談onlineは、日本最大級の教育情報Webメディア「リセマム」が提供する教育相談サービスです。幼児教育、小学校受験、中学受験、高校受験、大学受験、海外進学、国際教育、グローバル教育、進路相談、進路コンサル、留学、子育て相談や悩みについて専門家や先輩保護者などに「すぐに」「気軽に」「どこでも」相談いただけます。"
        />
    </div>
</template>
<script>
import ReviewLesson from "./components/review-lesson.vue";
import AvailableLesson from "@modules/pc/mypage/profile/components/available-lesson.vue";
import ProfileWithRate from "@modules/pc/mypage/profile/components/profile-with-rate.vue";
export default {
    data() {
        return {
            breadcrumbItems: [
                {
                    title: this.$t("menu.breadcrumb.my_page"),
                    link: "/my-page"
                },
                {
                    title: this.$t("menu.breadcrumb.profile"),
                    link: "#"
                }
            ]
        }
    },
    components: {
        ProfileWithRate,
        ReviewLesson,
        AvailableLesson
    }
};
</script>
<style scoped>
img {
    border-radius: 25px;
}

.intro {
    white-space: pre-line;
}
</style>